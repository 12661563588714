import React from 'react';
import { FormattedMessage } from 'react-intl';

import { CompactOpportunityCard } from 'src/components/Cards/CompactOpportunityCard';
import { Job } from 'src/global/models/Job';
import { UTM_REFERRER } from 'src/modules/Opportunities/constants';

import {
  CardContainer,
  CardContent,
  CardTitle,
  JobList,
  NewCard,
  NewCardHeaderLink,
} from '../../CompanyPage.sc';
import { CompanyDetailWithJobs } from '../../interface';

interface Props {
  topJobs: Job[];
  company: CompanyDetailWithJobs;
  ref: React.MutableRefObject<HTMLElement | null>;
  scrollToSectionHandler: () => void;
}

export const TopJobsSection = ({
  topJobs,
  company,
  ref,
  scrollToSectionHandler,
}: Props) => {
  return (
    <NewCard id="top-jobs-section" ref={ref}>
      <CardTitle>
        <FormattedMessage id="text-top-jobs" defaultMessage="Top Jobs" />
        <NewCardHeaderLink onClick={scrollToSectionHandler}>
          <FormattedMessage
            id="interactive-view-all-jobs"
            defaultMessage="View All Jobs"
          />
        </NewCardHeaderLink>
      </CardTitle>

      <CardContent>
        <JobList>
          <CardContainer>
            {topJobs?.map((opportunity, index) => (
              <CompactOpportunityCard
                key={opportunity.id}
                index={`${index}`}
                company={company}
                opportunity={opportunity}
                // TODO: remove this when CompactOpportunityCard is migrated to TS
                // @ts-ignore
                referrer={UTM_REFERRER.COMPANY_PROFILE}
              />
            ))}
          </CardContainer>
        </JobList>
      </CardContent>
    </NewCard>
  );
};
